import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Input, Dropdown, Grid } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { getPersonalList, personalList, deletePersonal } from '../../ducks/user';
import {
    countryStatusIsActive,
    countryRole,
    configTableUser,
    ACCESS_FACILITY_KEY,
} from '../../utils/const';
import ModalUser from './components/modal';
import useDebounce from '../../hooks/useDebounce';
import DefaultTable from '../../components/DefaultTable';

import './style.scss';

function UserList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(personalList);
    const [modalData, setModalData] = useState({ open: false, data: {} });
    const [filter, setFilter] = useState({
        roles: null,
        nameSubstring: null,
        pageSize: 20,
    });
    const debouncedFilter = useDebounce(filter, 200);
    const onClickCreate = useCallback(() => {
        setModalData(Object.assign({}, modalData, { open: true }));
    }, []);
    const onClickBack = useCallback(() => {
        localStorage.removeItem(ACCESS_FACILITY_KEY);
        history.push('/');
    }, []);
    const onClickEdit = (value) => {
        setModalData(Object.assign({}, modalData, { data: value, open: true }));
    };
    function fetchData() {
        dispatch(getPersonalList(debouncedFilter));
    }
    function closeModal() {
        setModalData(Object.assign({}, modalData, { data: null, open: false }));
    }
    const handleChange = (event, { name, value }) => {
        setFilter(
            Object.assign({}, filter, {
                [name]: value,
            }),
        );
    };
    const onClickDelete = (item) => {
        dispatch(deletePersonal({ id: item.id, callbackSuccess: fetchData }));
    };
    const handleUpdate = (e, { calculations }) => {
        const { bottomVisible } = calculations;
        if (bottomVisible && user.length >= filter.pageSize) {
            setFilter(Object.assign({}, filter, { pageSize: filter.pageSize + 20 }));
        }
    };

    useEffect(() => {
        fetchData();
    }, [debouncedFilter]);

    return (
        <div className="user-table">
            <div className="filter">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <h1 className="filter__title">Сотрудники</h1>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <button className="filter__button" onClick={onClickCreate}>
                                <p>
                                    <Icon name="plus" size="small" />
                                    Добавить сотрудника
                                </p>
                            </button>
                        </Grid.Column>
                        <Grid.Column width={2} floated="right">
                            <button className="filter__button" onClick={onClickBack}>
                                <p>
                                    <Icon name="angle left" size="small" />
                                    Назад
                                </p>
                            </button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="filter__row">
                        <Grid.Column width={3} className="filter__text">
                            ФИО
                            <Input
                                placeholder="ФИО сотрудника"
                                className="filter__input"
                                onChange={handleChange}
                                name="nameSubstring"
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="filter__text">
                            Роль
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                clearable
                                placeholder="Роль сотрудника"
                                options={countryRole}
                                name="roles"
                            />
                        </Grid.Column>
                        <Grid.Column width={3} className="filter__text">
                            Статус
                            <Dropdown
                                onChange={handleChange}
                                className="filter__input"
                                fluid
                                selection
                                clearable
                                placeholder="Выберите статус"
                                options={countryStatusIsActive}
                                name="isActive"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <DefaultTable
                essence={user}
                onClickDelete={onClickDelete}
                onClickEdit={onClickEdit}
                config={configTableUser}
                handleUpdate={handleUpdate}
            />
            {modalData.open && (
                <ModalUser fetchData={fetchData} modalData={modalData} closeModal={closeModal} />
            )}
        </div>
    );
}

export default UserList;
