import React, { useEffect, useState } from 'react';
import { Grid, Visibility, Icon, Input, Dropdown } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useHistory } from 'react-router-dom';

import useDebounce from '../../hooks/useDebounce';
import { ACCESS_FACILITY_KEY, configTableFacilities, countryActive } from '../../utils/const';
import ModalFacilities from './components/modal';
import { getFacilities, deleteFacilitiesRequest, facilitiesList } from '../../ducks/facilities';
import DefaultTable from '../../components/DefaultTable';
import { ls } from '../../utils/functionHelper';
import { USER_LINK } from '../../router/links';

import './style.scss';

function FacilitiesList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const facilities = useSelector(facilitiesList);
    const [filter, setFilter] = useState({
        number: null,
        statuses: null,
        createdFrom: null,
        pageSize: 20,
    });
    const debouncedFilter = useDebounce(filter, 200);
    const [modalData, setModalData] = useState({ open: false, data: {} });
    function closeModal() {
        setModalData(Object.assign({}, modalData, { data: null, open: false }));
    }
    const onClickCreate = () => {
        setModalData(Object.assign({}, modalData, { open: true }));
    };
    const onClickEdit = (value) => {
        setModalData(Object.assign({}, modalData, { data: value, open: true }));
    };
    const deleteFacilities = (item) => {
        dispatch(deleteFacilitiesRequest({ id: item.id, callbackSuccess: fetchData }));
    };
    const handleChange = (event, { name, value }) => {
        let newValue;

        if (name === 'date') {
            newValue = event?.target?.dataset?.testid?.substr(16);
        } else {
            newValue = value;
        }
        setFilter(
            Object.assign({}, filter, {
                [name]: newValue,
            }),
        );
    };
    const handleUpdate = (e, { calculations }) => {
        const { bottomVisible } = calculations;
        if (bottomVisible && facilities.length >= filter.pageSize) {
            setFilter(Object.assign({}, filter, { pageSize: filter.pageSize + 20 }));
        }
    };
    const onClickUser = (item) => {
        ls(ACCESS_FACILITY_KEY, item.alias);
        history.push(USER_LINK);
    };
    const onClickLink = (item) => {
        window.location.href = `https://${item.alias}${process.env.REACT_APP_URL}`;
    }

    function fetchData() {
        dispatch(getFacilities({ ...debouncedFilter }));
    }

    useEffect(() => {
        fetchData();
    }, [debouncedFilter]);

    return (
        <Visibility onUpdate={handleUpdate}>
            <div className="facilities">
                <div className="filter">
                    <Grid>
                        <Grid.Row className="filter__row">
                            <Grid.Column width={6}>
                                <h1 className="filter__title">Производство</h1>
                            </Grid.Column>
                            <Grid.Column width={5} floated="right">
                                <button className="filter__button" onClick={onClickCreate}>
                                    <p>
                                        <Icon name="plus" size="small" />
                                        Добавить производство
                                    </p>
                                </button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="filter__row">
                            <Grid.Column width={3} className="filter__text">
                                Статус
                                <Dropdown
                                    onChange={handleChange}
                                    placeholder="Выберите статус"
                                    className="filter__input"
                                    fluid
                                    selection
                                    clearable
                                    options={countryActive}
                                    name="isActive"
                                />
                            </Grid.Column>
                            <Grid.Column width={3} className="filter__text">
                                Название
                                <Input
                                    placeholder="Наименование"
                                    className="filter__input"
                                    onChange={handleChange}
                                    name="text"
                                />
                            </Grid.Column>
                            <Grid.Column width={2} className="filter__text">
                                Дата регистрации
                                <SemanticDatepicker
                                    locale="ru-RU"
                                    format="DD.MM.YYYY"
                                    onChange={handleChange}
                                    className="filter__input"
                                    iconPosition="left"
                                    icon="calendar alternate"
                                    placeholder="00.00.0000"
                                    name="createdFrom"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <DefaultTable
                    essence={facilities}
                    onClickDelete={deleteFacilities}
                    onClickEdit={onClickEdit}
                    onClickSupplement={onClickUser}
                    supplementIcon={'user outline'}
                    config={configTableFacilities}
                    handleUpdate={handleUpdate}
                    linkify={'linkify'}
                    onClickLink={onClickLink}
                />
                {modalData.open && (
                    <ModalFacilities
                        fetchData={fetchData}
                        modalData={modalData}
                        closeModal={closeModal}
                    />
                )}
            </div>
        </Visibility>
    );
}

export default FacilitiesList;
