import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Switch } from 'react-router-dom';

import { Navigation } from '../../components';
import { FacilitiesList } from '../';
import PrivateRoute from '../../router/privateRoute';
import { USER_LINK } from '../../router/links';
import { UserList } from '../index';

import './style.scss';

function MainPage() {
    return (
        <div className="main-page">
            <Grid columns={2} relaxed>
                <Grid.Column width={3}>
                    <Navigation />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Switch>
                        <PrivateRoute exact path={'/'} component={FacilitiesList} />
                        <PrivateRoute exact path={USER_LINK} component={UserList} />
                    </Switch>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default MainPage;
