import React from 'react';
import { Icon } from 'semantic-ui-react';
import { NavLink, useHistory } from 'react-router-dom';

import { LOGIN_LINK } from '../../router/links';
import { ACCESS_TOKEN_KEY } from '../../utils/const';

import './style.scss';

function Navigation() {
    const history = useHistory();
    const onClickExit = () => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        history.push(LOGIN_LINK);
    };

    return (
        <div className="navigation">
            <div className="navigation__top">
                <div className="navigation__logo"></div>
                <NavLink className="navigation__text" exact to={'/'}>
                    <Icon name="cut" />
                    ПРОИЗВОДСТВО
                </NavLink>
                <hr className="navigation__hr" />
            </div>
            <div className="navigation__bottom">
                <hr className="navigation__hr" />
                <button className="navigation__button--exit" onClick={onClickExit}>
                    Выход
                    <Icon name="sign-out" className="navigation__icon" />
                </button>
            </div>
        </div>
    );
}

export default Navigation;
