import React, { useMemo } from 'react';
import { Grid, Input, Modal, Button, Checkbox } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import className from 'classnames';

import {
    addFacilities,
    editFacilities,
    facilitiesError,
    clearError,
    getLoading,
} from '../../../ducks/facilities';
import { errorListFacilities } from '../../../utils/const';

function ModalFacilities({ fetchData, modalData, closeModal }) {
    const dispatch = useDispatch();
    const error = useSelector(facilitiesError);
    const loading = useSelector(getLoading);
    const { data, open } = modalData;
    const formik = useFormik({
        initialValues: {
            id: data ? data.id : null,
            name: data ? data.name : null,
            alias: data ? data.alias : null,
            isActive: data.isActive ? data.isActive : true,
        },
        onSubmit: (values) => {
            dispatch(
                !values.id
                    ? addFacilities({
                          data: values,
                          callbackSuccess,
                      })
                    : editFacilities({
                          data: values,
                          callbackSuccess,
                      }),
            );
        },
    });
    const onClose = () => {
        closeModal();
        dispatch(clearError());
    };
    const onChange = (event, { value, name }) => {
        setFieldValue('isActive', !values.isActive);
    };
    const { values, handleChange, handleSubmit, setFieldValue } = formik;
    const isNext = useMemo(() => {
        if (values.name && values.alias) {
            return false;
        }

        return true;
    }, [values]);

    function callbackSuccess() {
        closeModal();
        fetchData();
        dispatch(clearError());
    }

    return (
        <Modal size="tiny" centered={false} onClose={onClose} open={open}>
            <div className="modal">
                <div
                    className={className({
                        modal__error: error,
                    })}
                >
                    <p>{error && errorListFacilities[error]}</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h1 className="modal__title">Производство</h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Checkbox
                                    name="isActive"
                                    checked={values.isActive}
                                    toggle
                                    onChange={onChange}
                                    className="modal__check"
                                    label={values.isActive ? 'Включено' : 'Выключено'}
                                    type="checkbox"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    name="name"
                                    iconPosition="left"
                                    icon="cut"
                                    type="text"
                                    value={values.name}
                                    placeholder="Введите название"
                                    onChange={handleChange}
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="modal__row">
                            <Grid.Column>
                                <h3>Псевдоним</h3>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    name="alias"
                                    type="text"
                                    value={values.alias}
                                    placeholder="Введите псевдоним"
                                    onChange={handleChange}
                                    className="modal__input"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row />
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Button
                                    disabled={isNext || loading}
                                    type="submit"
                                    secondary
                                    content="Сохранить"
                                />
                            </Grid.Column>
                            <Grid.Column width={4} floated="right">
                                <p className="modal__button" onClick={onClose}>
                                    Отменить
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
}

export default ModalFacilities;
