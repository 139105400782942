import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Button, Icon } from 'semantic-ui-react';
import className from 'classnames';

import { errorListUser } from '../../../utils/const';
import { userAll, userError, authorization } from '../../../ducks/user';

function FormAuth() {
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        loginId: null,
        password: null,
    });
    const history = useHistory();
    const user = useSelector(userAll);
    const error = useSelector(userError);

    const handleSubmit = () => {
        dispatch(
            authorization({
                data: { loginId: idUser[0].id, password: form.password },
                callbackSuccess: () => history.push({ pathname: `/` }),
            }),
        );
    };
    const idUser = useMemo(() => user.filter((i) => i.text === form.loginId), [form]);
    const onChange = (event, { name, value }) => {
        setForm(
            Object.assign({}, form, {
                [name]: value,
            }),
        );
    };

    return (
        <div className="form_auth">
            <div className="form_auth__body">
                <Form onSubmit={handleSubmit}>
                    <div
                        className={className({
                            form_auth__error: error,
                        })}
                    >
                        <p>{error && errorListUser[error]}</p>
                    </div>
                    <h1 className="form_auth__title">Вход для админа</h1>
                    <Form.Field>
                        <Form.Select
                            trigger={
                                <span>
                                    <Icon name="user" />
                                    {form.loginId ? form.loginId : 'Выберите логин'}
                                </span>
                            }
                            name="loginId"
                            className="form_auth__input"
                            options={user}
                            onChange={onChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            className="form_auth__input"
                            placeholder="Введите пароль"
                            type="password"
                            icon="lock"
                            name="password"
                            iconPosition="left"
                            onChange={onChange}
                        />
                    </Form.Field>
                    <Button
                        type="submit"
                        disabled={!form.loginId || !form.password}
                        className="form_auth__button"
                        content="Вход"
                        basic
                        size="massive"
                    />
                </Form>
            </div>
        </div>
    );
}

export default FormAuth;
