export const ACCESS_TOKEN_KEY = 'accessToken';
export const ACCESS_FACILITY_KEY = 'accessFacility';

export const errorListUser = {
    'Not Found': 'Не удалось загрузить пользователей',
    Unauthorized: 'Введен неверный пароль',
    UserBlocked: 'Пользователь заблокирован',
};

export const errorListPersonal = {
    'Not Found': 'Не удалось загрузить пользователей',
    DuplicateEntry: 'Такой пользователь уже есть',
};

export const errorListFacilities = {
    DuplicateEntry: 'Псевдоним занят',
    IncorrectInput: 'Псевдоним не должен содержать кириллицу',
};

export const countryActive = [
    { id: 'Incomplete', value: false, text: 'Выключено', css: '--cuter' },
    { id: 'New', value: true, text: 'Включено', css: '--end' },
];

export const userRoleRus = {
    Producer: 'Швея',
    Master: 'Мастер',
    Operator: 'Оператор',
    Preproducer: 'Закрепщик',
    QA: 'ОТК',
    Manager: 'Менеджер',
};

export const configTableUser = [
    { title: 'ФИО', key: 'name', width: 4 },
    { title: 'Роль', key: 'role', width: 3 },
    { title: 'Статус', key: 'isActive', width: 3 },
    { title: '', key: '', width: 4 },
];

export const countryStatusIsActive = [
    { value: true, text: 'Активен', css: '--end' },
    { value: false, text: 'Неактивен', css: '--cuter' },
];

export const countryRole = [
    { id: 'Operator', value: 'Operator', text: 'Оператор', name: 'Оператор' },
    { id: 'Manager', value: 'Manager', text: 'Менеджер', name: 'Менаджер' },
];

export const USER_ROLES = {
    OPERATOR: 'Operator',
    MANAGER: 'Manager',
};

export const configTableFacilities = [
    { title: 'Название', key: 'name', width: 4 },
    { title: 'Псевдоним', key: 'alias', width: 1 },
    { title: 'Дата регистрации', key: 'createDate', width: 3 },
    { title: 'Статус', key: 'isActive', width: 3 },
    { title: '', key: '', width: 4 },
];
