import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { LOGIN_LINK } from './links';

import { AuthorizationAdmin, MainPage } from '../pages';
import PrivateRoute from './privateRoute';

function MainRoute() {
    return (
        <Switch>
            <Route exact path={LOGIN_LINK} component={AuthorizationAdmin} />
            <PrivateRoute path="/" component={MainPage} />
        </Switch>
    );
}

export default MainRoute;
