import { countryActive } from './const';
import { format } from 'date-fns';

export function setOptions(arr) {
    return arr.map((value) => {
        return {
            id: value.id,
            value: value.name,
            text: value.name,
        };
    });
}

export function dateFormatDD(date) {
    return format(date, 'dd.MM.yyyy');
}

export function addStatusIsActive(str) {
    const item = countryActive.find((i) => i.value === str);
    return (
        item && (
            <pre>
                <a className={`status${item.css}`} />
                {item.text}
            </pre>
        )
    );
}

export function setOptionsId(arr) {
    return arr.map((value) => {
        return {
            id: value.id,
            value: value.id,
            text: value.name,
        };
    });
}

export function ls(key, value) {
    let _value = value;

    if (typeof value === 'object') {
        _value = JSON.stringify(_value);
    }

    if (arguments.length === 2) {
        localStorage.setItem(key, _value);
    } else {
        return localStorage.getItem(key);
    }
}
