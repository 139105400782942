import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { parseISO } from 'date-fns';

import { postman } from '../utils/postman';
import { dateFormatDD } from '../utils/functionHelper';

//*                                                       /Facilities                                                *//

//*  TYPES  *//

export const GET_FACILITIES_LIST_REQUEST = 'GET_FACILITIES_LIST_REQUEST';
export const GET_FACILITIES_LIST_SUCCESS = 'GET_FACILITIES_LIST_SUCCESS';

export const ADD_NEW_FACILITIES_REQUEST = 'ADD_NEW_FACILITIES_REQUEST';
export const ADD_NEW_FACILITIES_SUCCESS = 'ADD_NEW_FACILITIES_SUCCESS';

export const EDIT_FACILITIES_REQUEST = 'EDIT_FACILITIES_REQUEST';
export const EDIT_FACILITIES_SUCCESS = 'EDIT_FACILITIES_SUCCESS';

export const DELETE_FACILITIES_REQUEST = 'DELETE_ORDERS_REQUEST';
export const DELETE_FACILITIES_SUCCESS = 'DELETE_ORDERS_SUCCESS';

export const ERROR_HANDLER_FACILITIES = 'ERROR_HANDLER_FACILITIES';

//*  INITIAL STATE  *//

const initial = {
    loading: false,
    error: null,
    items: [],
    itemsCount: 0,
    pagesCount: 0,
};

//*  REDUCER  *//

export default function facilities(state = initial, action) {
    switch (action.type) {
        case GET_FACILITIES_LIST_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case ERROR_HANDLER_FACILITIES:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ADD_NEW_FACILITIES_REQUEST:
        case EDIT_FACILITIES_REQUEST:
        case DELETE_FACILITIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_NEW_FACILITIES_SUCCESS:
        case EDIT_FACILITIES_SUCCESS:
        case DELETE_FACILITIES_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const deleteFacilitiesRequest = (payload) => {
    return {
        type: DELETE_FACILITIES_REQUEST,
        payload,
    };
};

export const clearError = () => {
    return {
        type: ERROR_HANDLER_FACILITIES,
    };
};

export const addFacilities = (payload) => {
    return {
        type: ADD_NEW_FACILITIES_REQUEST,
        payload,
    };
};

export const editFacilities = (payload) => {
    return {
        type: EDIT_FACILITIES_REQUEST,
        payload,
    };
};

export const getFacilities = (payload) => {
    return {
        type: GET_FACILITIES_LIST_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseFacilitiesState = (state) => state.facilities;

export const getLoading = createSelector([baseFacilitiesState], (state) => state.loading);

export const facilitiesList = createSelector(
    [baseFacilitiesState],
    (data) =>
        data.items &&
        data.items.map((value) => {
            return {
                ...value,
                createDate: dateFormatDD(parseISO(value.createDate)),
            };
        }),
);

export const facilitiesError = createSelector([baseFacilitiesState], (state) => state.error);

//*  SAGA  *//

function* workerGetFacilities({ payload }) {
    try {
        const { isActive, text, createdFrom, pageSize } = payload;
        const { itemsCount, pagesCount, items } = yield postman.get(`/Facilities`, {
            params: {
                isActive,
                text,
                createdFrom,
                pageSize,
            },
        });

        yield put({
            type: GET_FACILITIES_LIST_SUCCESS,
            payload: {
                itemsCount,
                pagesCount,
                items,
            },
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerDeleteFacilities({ payload }) {
    try {
        const { id, callbackSuccess } = payload;

        yield postman.delete(`/Facilities/${id}`);
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerEditFacilities({ payload }) {
    try {
        const { data, callbackSuccess } = payload;

        yield postman.put(`/Facilities/${data.id}`, data);

        yield put({
            type: EDIT_FACILITIES_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_FACILITIES,
            payload: data.errorCode,
        });
    }
}

function* workerAddFacilities({ payload }) {
    try {
        const { data, callbackSuccess } = payload;

        yield postman.post(`/Facilities`, data);

        yield put({
            type: ADD_NEW_FACILITIES_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_FACILITIES,
            payload: data.errorCode,
        });
    }
}

export function* watchFacilities() {
    yield takeEvery(GET_FACILITIES_LIST_REQUEST, workerGetFacilities);
    yield takeEvery(DELETE_FACILITIES_REQUEST, workerDeleteFacilities);
    yield takeEvery(ADD_NEW_FACILITIES_REQUEST, workerAddFacilities);
    yield takeEvery(EDIT_FACILITIES_REQUEST, workerEditFacilities);
}
