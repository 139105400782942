import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { setOptions, ls } from '../utils/functionHelper';
import { postman, setAccessToken } from '../utils/postman';
import { ACCESS_FACILITY_KEY, ACCESS_TOKEN_KEY, USER_ROLES } from '../utils/const';

//*                                                       /Auth                                              *//

//*  TYPES  *//

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';

export const GET_PERSONAL_LIST_REQUEST = 'GET_PERSONAL_LIST_REQUEST';
export const GET_PERSONAL_LIST_SUCCESS = 'GET_PERSONAL_LIST_SUCCESS';

export const DELETE_PERSONAL_REQUEST = 'DELETE_PERSONAL_REQUEST';
export const DELETE_PERSONAL_SUCCESS = 'DELETE_PERSONAL_SUCCESS';

export const ADD_PERSONAL_REQUEST = 'ADD_PERSONAL_REQUEST';
export const ADD_PERSONAL_SUCCESS = 'ADD_PERSONAL_SUCCESS';

export const EDIT_PERSONAL_REQUEST = 'EDIT_PERSONAL_REQUEST';
export const EDIT_PERSONAL_SUCCESS = 'EDIT_PERSONAL_SUCCESS';

export const AUTHORIZATION_USER_REQUEST = 'AUTHORIZATION_USER_REQUEST';
export const AUTHORIZATION_USER_SUCCESS = 'AUTHORIZATION_USER_SUCCESS';

export const ERROR_HANDLER_USER = 'ERROR_HANDLER_USER';

export const ERROR_HANDLER_PERSONAL = 'ERROR_HANDLER_PERSONAL';

//*  INITIAL STATE  *//

const initial = {
    user: [],
    error: null,
    isAuth: false,
    errorAddUser: null,
    personalList: [],
    loading: false,
};

//*  REDUCER  *//

export default function user(state = initial, action) {
    switch (action.type) {
        case GET_USER_LIST_SUCCESS:
            return {
                ...state,
                user: action.payload ? action.payload : [],
            };
        case ERROR_HANDLER_USER:
            return {
                ...state,
                error: action.payload,
            };
        case ERROR_HANDLER_PERSONAL: {
            return {
                ...state,
                errorAddUser: action.payload,
                loading: false,
            };
        }
        case GET_PERSONAL_LIST_SUCCESS:
        case ADD_PERSONAL_SUCCESS:
        case EDIT_PERSONAL_SUCCESS:
        case DELETE_PERSONAL_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case ADD_PERSONAL_REQUEST:
        case EDIT_PERSONAL_REQUEST:
        case DELETE_PERSONAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AUTHORIZATION_USER_SUCCESS:
            return {
                ...state,
                isAuth: true,
                error: null,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const addPersonal = (payload) => {
    return {
        type: ADD_PERSONAL_REQUEST,
        payload,
    };
};

export const editPersonal = (payload) => {
    return {
        type: EDIT_PERSONAL_REQUEST,
        payload,
    };
};

export const deletePersonal = (payload) => {
    return {
        type: DELETE_PERSONAL_REQUEST,
        payload,
    };
};

export const getPersonalList = (payload) => {
    return {
        type: GET_PERSONAL_LIST_REQUEST,
        payload,
    };
};

export const getUserList = (payload) => {
    return {
        type: GET_USER_LIST_REQUEST,
        payload,
    };
};

export const authorization = (payload) => {
    return {
        type: AUTHORIZATION_USER_REQUEST,
        payload,
    };
};

export const clearError = () => {
    return {
        type: ERROR_HANDLER_PERSONAL,
    };
};

//*  SELECTORS *//

export const baseUserState = (state) => state.user;

export const userAll = createSelector(
    [baseUserState],
    (data) => data.user && setOptions(data.user),
);
export const userError = createSelector([baseUserState], (state) => state.error);

export const addUserError = createSelector([baseUserState], (state) => state.errorAddUser);

export const personalList = createSelector([baseUserState], (state) => state.personalList);

export const getLoading = createSelector([baseUserState], (state) => state.loading);

//*  SAGA  *//

function* workerAuthorization({ payload }) {
    try {
        const { callbackSuccess, data } = payload;
        const response = yield postman.post(`/Auth/Login`, data);

        ls(ACCESS_TOKEN_KEY, response.token);
        setAccessToken(response.token);

        yield put({
            type: AUTHORIZATION_USER_SUCCESS,
        });
        callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_USER,
            payload: data.title,
        });
    }
}

function* workerGetUser() {
    try {
        const response = yield postman.get(`/Auth/Users`);

        yield put({
            type: GET_USER_LIST_SUCCESS,
            payload: response,
        });
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_USER,
            payload: data.title,
        });
    }
}

function* workerGetPersonal({ payload }) {
    try {
        let { roles, nameSubstring, pageSize, isActive } = payload;

        roles = roles ? roles : Object.values(USER_ROLES);
        const { items: personalList } = yield postman.get(`/DashboardUsers`, {
            headers: {
                'X-Facility-Alias': ls(ACCESS_FACILITY_KEY),
            },
            params: {
                roles,
                nameSubstring,
                pageSize,
                isActive,
            },
        });

        yield put({
            type: GET_PERSONAL_LIST_SUCCESS,
            payload: { personalList },
        });
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_USER,
            payload: data.title,
        });
    }
}

function* workerDeletePersonal({ payload }) {
    try {
        const { id, callbackSuccess } = payload;
        yield postman.delete(`/DashboardUsers/${id}`, {
            headers: {
                'X-Facility-Alias': ls(ACCESS_FACILITY_KEY),
            },
        });

        yield put({
            type: DELETE_PERSONAL_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_USER,
            payload: data.title,
        });
    }
}

function* workerAddPersonal({ payload }) {
    try {
        const { data, callbackSuccess } = payload;
        yield postman.post(`/DashboardUsers`, data, {
            headers: {
                'X-Facility-Alias': ls(ACCESS_FACILITY_KEY),
            },
        });

        yield put({
            type: ADD_PERSONAL_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_PERSONAL,
            payload: data.errorCode,
        });
    }
}

function* workerEditPersonal({ payload }) {
    try {
        const { data, callbackSuccess } = payload;
        yield postman.put(`/DashboardUsers/${data.id}`, data, {
            headers: {
                'X-Facility-Alias': ls(ACCESS_FACILITY_KEY),
            },
        });

        yield put({
            type: EDIT_PERSONAL_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_PERSONAL,
            payload: data.errorCode,
        });
    }
}

export function* watchUser() {
    yield takeEvery(AUTHORIZATION_USER_REQUEST, workerAuthorization);
    yield takeEvery(GET_USER_LIST_REQUEST, workerGetUser);
    yield takeEvery(GET_PERSONAL_LIST_REQUEST, workerGetPersonal);
    yield takeEvery(DELETE_PERSONAL_REQUEST, workerDeletePersonal);
    yield takeEvery(ADD_PERSONAL_REQUEST, workerAddPersonal);
    yield takeEvery(EDIT_PERSONAL_REQUEST, workerEditPersonal);
}
