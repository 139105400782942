import React from 'react';

function Header() {
    return (
        <div className="authorization-header">
            <div className="authorization-header__body">
                <a className="authorization-header__logo" />
                <div className="authorization-header__nav">
                </div>
            </div>
        </div>
    );
}

export default Header;
