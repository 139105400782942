import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import FormAuth from './components/formAuth'
import Header from './components/header'
import { getUserList } from '../../ducks/user';

import './style.scss'

function AuthorizationAdmin() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserList());
    }, []);

    return (
        <div className="authorization">
            <div className="authorization__body">
                <Header />
                <FormAuth />
            </div>
        </div>
    );
}

export default AuthorizationAdmin;
