import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import MainRoute from './router';
import { store } from './store/store';

import './styles/app.scss';
import 'semantic-ui-css/semantic.min.css';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <MainRoute />
        </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

