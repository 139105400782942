import axios from 'axios';
import qs from 'qs';

import { ACCESS_FACILITY_KEY, ACCESS_TOKEN_KEY } from './const';
import { ls } from './functionHelper';

const URL_API = 'https://admin-api.trinitycrm.ru/api';
/**
 * Задание базового URL и сериализация параметров
 * @type {AxiosInstance}
 */
export const postman = axios.create({
    baseURL: URL_API,
    paramsSerializer: (params) => qs.stringify(params, { indices: false, arrayFormat: 'repeat' }),
});

export const downloader = axios.create({
    baseURL: URL_API,
});
/**
 * Добавление параметров конфигурации для запросов по умолчанию
 */
postman.interceptors.request.use((config) => {
    config.params = config.params || {};
    return config;
});
/**
 * Обработка ответа запроса
 */
postman.interceptors.response.use(
    (resp) => {
        return resp.data;
    },
    (error) => {
        if (error.response) {
            const { status } = error.response;

            if (status === 401 && window.location.pathname !== '/login') {
                localStorage.clear();
                window.location.reload();
            }
            throw error.response;
        }

        throw error;
    },
);
/**
 * Добавление/удаление токена доступа для заголовка запроса
 * @param token string
 */
export let setAccessToken = (token) => {
    if (token !== null) {
        postman.defaults.headers.common.Authorization = `Bearer ${token}`;
        downloader.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        delete postman.defaults.headers.common.Authorization;
        delete downloader.defaults.headers.common.Authorization;
    }
};

setAccessToken(ls(ACCESS_TOKEN_KEY));
